import type { LicenceFlag } from "~/types/enums"

import union from "lodash/union"

import { useAccountStore } from "~/stores/account"
import { useAuthStore } from "~/stores/auth"
import { isLicenceActive } from "~/utils/permissions"

export function hasLicenceFlag(flag: LicenceFlag): boolean {
  const authStore = useAuthStore()

  const { currentAccount } = useAccountStore()

  // If we're not currently in an account context (i.e. a project) then we check against the user
  if (!currentAccount) {
    // Global users have all licence flags
    return authStore.user != null && authStore.user.accountId == null
  }

  const activeLicence = currentAccount.licences.find(isLicenceActive)
  if (!activeLicence) return false

  return union(activeLicence.flags, activeLicence.plan?.flags).includes(flag)
}
